/* Estilos del contenedor del chat */
.chat-container {
    background-color: #1f2937; /* Color oscuro */
    color: #f9fafb; /* Color del texto */
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Caja de chat */
  .chat-box {
    height: 300px; /* Ajusta la altura según lo necesario */
    overflow-y: auto;
    background-color: #111827; /* Fondo más oscuro para los mensajes */
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    color: #f9fafb;
  }
  
  /* Mensaje */
  .message {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
  }
  
  .bot-message {
    background-color: #374151; /* Mensaje del bot */
    color: #f9fafb;
    align-self: flex-start;
  }
  
  .user-message {
    background-color: #6c63ff; /* Mensaje del usuario */
    color: white;
    align-self: flex-end;
  }
  
  /* Área de entrada de texto */
  .input-area {
    display: flex;
    align-items: center;
  }
  
  input[type="text"] {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #374151;
    background-color: #1f2937;
    color: #f9fafb; /* Color del texto */
    outline: none;
  }
  
  input[type="text"]::placeholder {
    color: #9ca3af; /* Color del placeholder */
  }
  
  button {
    padding: 10px 20px;
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #5755d9;
  }
  